import React from 'react';
import PriceForSmlScreen from './PriceSmlScreen';
import PriceForFullScreen from './PriceFullScreen';

const Prices = () => {
    const screenWidth = window.screen.width;
    //console.log(screenWidth);

    return ( 
        
        <div id="PricesSection" className="section secondary-section">{/*-- Price section start -->*/}
            <div className="triangle animated fadeInDown"></div>
            <div className="container">
                <div className="title">
                    <h1>Price</h1>
                 </div>
                    {screenWidth < 768 && PriceForSmlScreen()}
                    {screenWidth >= 768 && PriceForFullScreen()}
                <div className="">
                    <p className="price-text">
                        <strong className="strong-price">All-inclusive: </strong>All tier offers are applicable to all enterprise databases 
                        (PostgreSQL, MySQL, MariaDB, MSSQL, Oracle & all products of the Oracle Core Technologies family)
                         inclusing and not limited by:
                        <ul className='strong-price'>
                            <li>New installations;</li>
                            <li>Backups;</li>
                            <li>Clustering & replications;</li>
                            <li>Performance optimization;</li>
                            <li>Consulting;</li>
                            <li>24/7/365 Monitoring & Support;</li>
                            </ul></p>
                            <ul className='ref-price'>
                        <li><span className='siar-img'>*</span>
                                    T & M projects are priced separately under additional agreements.
                                    </li>
                        <li><span className='siar-img'>*</span>
                                    Oracle RAC support is opt out for the Small package.
                                    </li>
                                </ul>
                    <div className='centered'><a href="#ContactUsSection" className="button  button-contact homebutton" >
                            Request a Quote</a></div>
                </div>
            </div> {/*<!-- Price section end -->*/}
        </div>
       
     );
}
 
export default Prices;