import { useState } from 'react';
import PopupPrivacyPolicy from '../Popups/PopupPrivacyPolicy';
import PopupTerms from '../Popups/PopupTerms';
import Flag from './../../images/Flag.svg';
const Footer = () => {
    const [PopupPolicyOpen, setPopupPolicyOpen] = useState(false);
    const [PopupTermsOpen, setPopupTermsOpen] = useState(false);
    return (  

        <div className="footer">
            <div className="row-fluid">
                <div className="span4" >
                    <strong>About Us</strong>
                 </div>
                <div className="span4" >
                <strong>Address</strong>
                </div>
                <div className="span4" >
                    <strong>Terms and Conditions</strong>
                </div>
             </div>
             <div className="row-fluid">
                <div className="span4" >
                    <p>IOMatters is a remote DBA service provider based in the USA.</p>
                    <div className="row-fluid">
            </div>
            <div className="row-fluid centered">
            <ul className="social">
                <li>
                    <a href=""><span className="icon-facebook-circled"></span> </a>
                </li>
                <li>
                    <a href=""><span className="icon-twitter-circled"></span></a>
                </li>
                <li>
                    <a href=""><span className="icon-linkedin-circled"></span></a>
                </li>
            </ul>
            </div>
                 </div>
                <div className="span4  contact-info" >
                    <p>6608 DuVon Pl, Manassas, 20111, VA</p>
                    <a href="mailto:support@iomatters.com"><p><span className='icon-email' style={{fontSize: '14px'}}></span> support@iomatters.com</p></a>
                    <p><span className='icon-phone' style={{ fontSize: '14px' }}></span> +1 (301) 246-2070</p>
                </div>
                <div className="span4 contact-info" >
                   <p> <ul>
                        <a className='footer-a' onClick={() => setPopupPolicyOpen(true)}> <li>Privacy Policy</li></a>
                        <a className='footer-a' onClick={() => setPopupTermsOpen(true)}> <li>Terms and Conditions</li></a>
                        <p className=''>
                        <img src={Flag} width={'60px'}/></p>
                    </ul></p>
                    
                    
                </div>
             </div>
            {PopupPolicyOpen &&(
                <PopupPrivacyPolicy trigger={PopupPolicyOpen} setTrigger={setPopupPolicyOpen}
                onClose={() => setPopupPolicyOpen(false)} />
            )}
            {PopupTermsOpen && (
                <PopupTerms trigger={PopupTermsOpen} setTrigger={setPopupTermsOpen}
                    onClose={() => setPopupTermsOpen(false)} />
            )}
               

            
        <p>&copy;Copyright  2024  IOmatters All rights reserved</p>
    </div>
    );
}
 
export default Footer;