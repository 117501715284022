import React, { useEffect, useState } from "react";


const TextSlider = ({ texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2700);

    return () => {
      clearInterval(interval);
    };
  }, [texts.length]);

  return (

    <div className="text-slider"> 

      {texts.map((text, index) => (
        <div
          key={index}
          className={`slider-item ${index === currentIndex ? "active" : ""}`}
        >
          <h4>{text.boldText} < span>{text.simplText}</span></h4>
        </div>
      ))}
    </div>
  );
};

export default TextSlider;