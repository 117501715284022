import ServiceBk from './../images/Services/Service_bkp.svg';
import ServiceSc from './../images/Services/Service_sc.svg';
import ServiceMU from './../images/Services/Service_mu.svg';
import Service24 from './../images/Services/Service24.svg';
import ServiceClBd from './../images/Services/Service_clBd.svg';
import ServiceCLRp from './../images/Services/Service_clRp.svg';
import ServiceDs from './../images/Services/Service_Ds.svg';
import ServiceOpt from './../images/Services/Service_Opt.svg';
import ServiceC from './../images/Services/Service_C.svg';


const ServiceList=[
    {
        id:1,
        title:"Data Migrations & Upgrades",
        skills:"Infrastructure migration. Data migration.Application migration.Cloud migration.Cloud-To-Cloud migration",
        img: ServiceMU,
    },
    {
        id:2,
        title:"Clustering & Replications",
        skills:"Database replication strategies aligned with your business requirements, incl. Failover/LB clustering and custom solutions.",
        img: ServiceCLRp,
    },
    {
        id:3,
        title:"Performance Tuning & Optimization",
        skills:"Leveraging proprietary and embedded tools to analyze execution plans, indexes, partitions, statistics, cache, server resources  etc.",
        img: ServiceOpt,
    },
    {
        id:4,
        title:"Database Design & Architecture",
        skills:"IPlanning Databases with a focus on scalability, In-house, Cloud, Kubernetes; All aspects of designing Database schemas.",
        img: ServiceDs,
    },
    {
        id:5,
        title:"Backups & Disaster Recovery",
        skills:"Implementing robust strategies including Database Clusters and geographical replication to minimize downtimes.",
        img: ServiceBk,
    },
    {
        id:6,
        title:"Cloud Audit & Budget optimization",
        skills:"We audit your Cloud infrastructure to get a holistic view on the resource consumption.",
        img: ServiceClBd,
    },
    {
        id:7,
        title:"Enhanced Data Security",
        skills:"IOur highest priority is to protect your data. We harden your Databases and ensure secure data transmission.",
        img: ServiceSc,
    },
    {
        id:8,
        title:"24/7 Observability",
        skills:"Are you planning on a new IT system, or on modifying, expanding, transforming existing databases? We are here to help!",
        img: Service24,
    },
    {
        id:9,
        title:"Consulting",
        skills:"We audit your Cloud infrastructure to get a holistic view on the resource consumption.",
        img: ServiceC,
    }
]
export {ServiceList};