import React, { useRef } from 'react';
import checkSvg from './../../images/check-svg.svg'

function PopupPrivacyPolicy(props) {
    const popupRef = useRef(null);

    const handleClickOutside = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            props.setTrigger(false);
        }
    };
    const handleClick = (e, id) => {
        e.preventDefault(); 
        e.stopPropagation(); 
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (props.trigger) ? (
        <div className="overlay" onClick={handleClickOutside}>
            <div ref={popupRef} className="inner-lay popup-inner animated pulse contact">
                <div className='position-close-btn'>
                    <button className="close-btn" onClick={() => props.setTrigger(false)}><span className='icon-cancel' ></span>
                    </button>
                </div>
                <div className="container priv-pol scrollable-content">
                    <div ><h2>PRIVACY POLICY</h2></div>
                    <div ><h3><b>PRIVACY NOTICE</b></h3></div>
                    <div className="colorBlack" >
                        <p>Last updated June 29, 2023<br />
                        Thank you for choosing to be part of our community at High Project corp
                         («Company», «we», «us», «our»). 
                         We are committed to protecting your personal information and your right 
                         to privacy. If you have any questions or concerns about this privacy notice,
                         or our practices with regards to your personal information, please contact us 
                        at <a href="mailto:support@iomatters.com" className="link-content">
                                    <span className='icon-email' style={{ fontSize: '14px', color:"#FECE1A"}}>
                                        </span> support@iomatters.com</a> When
                         you visit our website, and more generally, use any of our services 
                        (the «Services», which include the Website), we appreciate that you are 
                        trusting us with your personal information. We take your privacy very seriously. 
                        In this privacy notice, we seek to explain to you in the clearest way possible 
                        what information we collect, how we use it and what rights you have in relation to it.
                         We hope you take some time to read through it carefully, as it is important. 
                         If there are any terms in this privacy notice that you do not agree with, 
                         please discontinue use of our Services immediately.<br />
                        This privacy notice applies to all information collected through our Services 
                        (which, as described above, includes our Website), as well as, any related services, 
                        sales, marketing or events.<br />
                        <b>Please read this privacy notice carefully as it will help you understand 
                            what we do with the information that we collect.</b></p>
                    </div>
                    <div className=""><h3><b>TABLE OF CONTENTS</b></h3><div>
                        <div className="colorBlack">
                        <ol className="contents__link">
                                <li ><a href="#infocollect" className="link-content" onClick={(e) => handleClick(e, 'infocollect')}>WHAT INFORMATION DO WE COLLECT?</a></li>
                                <li ><a href="#infouse" className="link-content" onClick={(e) => handleClick(e, 'infouse')}>HOW DO WE USE YOUR INFORMATION?</a></li>
                                <li ><a href="#infoshare" className="link-content" onClick={(e) => handleClick(e, 'infoshare')}>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></li>
                                <li ><a href="#inforetain" className="link-content" onClick={(e) => handleClick(e, 'inforetain')}>HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                                <li ><a href="#infosafe" className="link-content" onClick={(e) => handleClick(e, 'infosafe')}>HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                                <li ><a href="#infominors" className="link-content" onClick={(e) => handleClick(e, 'infominors')}>DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                                <li ><a href="#privacyrights-7" className="link-content" onClick={(e) => handleClick(e, 'privacyrights-7')}>WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                                <li ><a href="#DNT" className="link-content" onClick={(e) => handleClick(e, 'DNT')}>CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                                <li ><a href="#caresidents" className="link-content" onClick={(e) => handleClick(e, 'caresidents')}>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                                <li ><a href="#policyupdates" className="link-content" onClick={(e) => handleClick(e, 'policyupdates')}>DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                                <li ><a href="#contact-11" className="link-content" onClick={(e) => handleClick(e, 'contact-11')}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                                <li ><a href="#request" className="link-content" onClick={(e) => handleClick(e, 'request')}>HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                        </ol></div>
                        <div id="infocollect"><h3><b>1. WHAT INFORMATION DO WE COLLECT?</b></h3></div>
                        <div className="colorBlack contents__link"><p><b>Personal information you disclose to us</b></p>
                            <p ><i><b>In Short:</b></i>
                            <i> We collect personal information that you provide to us.</i></p>
                            <p >We collect personal information that 
                            you voluntarily provide to us when you express an interest in obtaining 
                            information about us or our products and Services, when you participate 
                            in activities on the Website or otherwise when you contact us.<br />
                            The personal information that we collect depends on the context of your 
                            interactions with us and the Website, the choices you make and the products 
                            and features you use. The personal information we collect may include the 
                            following:<br />
                            Personal Information Provided by You. We collect names; phone numbers; 
                            email addresses; and other similar information.<br />
                            All personal information that you provide to us must be true, complete 
                            and accurate, and you must notify us of any changes to such personal 
                            information.</p>
                                <p>Information automatically collected</p>
                                <p ><i><b>In Short: </b></i>
                                <i>Some information — such as your Internet Protocol (IP) address 
                                    and/or browser and device characteristics — is collected automatically 
                                    when you visit our Website.</i></p>
                                <p >We automatically collect certain information when you visit, 
                                use or navigate the Website. This information does not reveal 
                                your specific identity (like your name or contact information) 
                                but may include device and usage information, such as your 
                                IP address, browser and device characteristics, operating system,
                                language preferences, referring URLs, device name, country, 
                                location, information about how and when you use our Website 
                                and other technical information. This information is primarily 
                                needed to maintain the security and operation of our Website, 
                                and for our internal analytics and reporting purposes.</p>
                                <p>The information we collect includes:</p>
                            <p className="contents__link"><img width='20px' height='20px' src={checkSvg} /> 
                            <b> Log and Usage Data.</b> 
                            Log and usage data is service-related, diagnostic, 
                            usage and performance information our servers automatically 
                            collect when you access or use our Website and which we record 
                            in log files.Depending on how you interact with us, this log 
                            data may include your IP address, device information, browser 
                            type and settings and information about your activity in the 
                            Website (such as the date/time stamps associated with your 
                            usage, pages and files viewed, searches and other actions you 
                            take such as which features you use), device event information 
                            (such as system activity, error reports (sometimes called 
                            ‘crash dumps’) and hardware settings).<br />
                            <img width='20px' height='20px' src={checkSvg} /> 
                            <b> Device Data. </b>
                            We collect device data such as information about your computer, 
                            phone, tablet or other device you use to access the Website. 
                            Depending on the device used, this device data may include 
                            information such as your IP address (or proxy server), device 
                            and application identification numbers, location, browser type, 
                            hardware model Internet service provider and/or mobile carrier, 
                            operating system and system configuration information.<br />
                            <img width='20px' height='20px' src={checkSvg} /> 
                            <b> Location Data.</b> 
                            We collect location data such as information about your 
                            device’s location, which can be either precise or imprecise. 
                            How much information we collect depends on the type and settings
                             of the device you use to access the Website. For example, 
                             we may use GPS and other technologies to collect geolocation 
                             data that tells us your current location (based on your IP 
                             address). You can opt out of allowing us to collect this 
                             information either by refusing access to the information or by 
                             disabling your Location setting on your device. Note however, 
                             if you choose to opt out, you may not be able to use certain 
                             aspects of the Services.</p>
                        </div>
                        <div id="infouse"><h3><b>2. HOW DO WE USE YOUR INFORMATION?</b></h3></div>
                        <div className="colorBlack contents__link"><p ><i><b>In Short:</b> </i>
                        <i>We process your information for purposes based on legitimate business 
                        interests, the fulfillment of our contract with you, compliance with our 
                        legal obligations, and/or your consent.</i></p>
                        <p >We use personal information collected via our Website for a variety 
                            of business purposes described below. We process your personal 
                            information for these purposes in reliance on our legitimate business 
                            interests, in order to enter into or perform a contract with you, 
                            with your consent, and/or for compliance with our legal obligations. 
                            We indicate the specific processing grounds we rely on next to each 
                            purpose listed below.</p>
                        <p>We use the information we collect or receive:</p>
                            <p className='contents__link'><img width='20px' height='20px' src={checkSvg} /> 
                        <b>To send administrative information to you. </b>
                        We may use your personal information to send you product, 
                        service and new feature information and/or information 
                        about changes to our terms, conditions, and policies.<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>To protect our Services.</b> 
                        We may use your information as part of our efforts to keep 
                        our Website safe and secure (for example, for fraud 
                        monitoring and prevention).<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>To enforce our terms, </b>
                        conditions and policies for business purposes, to comply 
                        with legal and regulatory requirements or in connection 
                        with our contract.<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>To respond to legal requests and prevent harm. </b>
                        If we receive a subpoena or other legal request, we may 
                        need to inspect the data we hold to determine how to 
                        respond.<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>Fulfill and manage your orders.</b> 
                        We may use your information to fulfill and manage your 
                        orders, payments, returns, and exchanges made through the 
                        Website.<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>Administer prize draws and competitions.</b> 
                        We may use your information to administer prize draws and 
                        competitions when you elect to participate in our 
                        competitions.<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>To deliver and facilitate delivery of services to 
                            the user.</b> 
                        We may use your information to provide you with the 
                        requested service.<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>To respond to user inquiries/offer support to users.</b>
                        We may use your information to respond to your inquiries and 
                        solve any potential issues you might have with the use of our 
                        Services.<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>To send you marketing and promotional communications.</b> 
                        We and/or our third-party marketing partners may use the 
                        personal information you send to us for our marketing 
                        purposes, if this is in accordance with your marketing 
                        preferences. For example, when expressing an interest in 
                        obtaining information about us or our Website, subscribing 
                        to marketing or otherwise contacting us, we will collect 
                        personal information from you. You can opt-out of our 
                        marketing emails at any time (see the 
                                <a href="#privacyrights-7" className="link-content">«WHAT ARE YOUR PRIVACY RIGHTS?»</a> 
                        below).<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        <b>Deliver targeted advertising to you.</b> 
                        We may use your information to develop and display personalized 
                        content and advertising (and work with third parties who do so) 
                        tailored to your interests and/or location and to measure its 
                        effectiveness.</p> 
                    </div>
                     <div id="infoshare">
                        <h3><b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</b></h3>
                    </div>
                        <div className="colorBlack contents__link">
                        <p ><i><b>In Short:</b> </i>
                    <i>We only share information with your consent, to comply with laws, 
                    to provide you with services, to protect your rights, or to fulfill 
                    business obligations.</i></p>
                    <p >We may process or share your data that we hold based on the following 
                        legal basis:</p>
                            <p className="contents__link"><img width='20px' height='20px' src={checkSvg} /> 
                    <b>Consent: </b>We may process your data if you have given us specific 
                    consent to use your personal information for a specific purpose.<br />
                    <img width='20px' height='20px' src={checkSvg} /> 
                    <b>Legitimate Interests:</b> We may process your data when it is 
                    reasonably necessary to achieve our legitimate business interests.<br />
                    <img width='20px' height='20px' src={checkSvg} /> 
                    <b>Performance of a Contract:</b> Where we have entered into a contract 
                    with you, we may process your personal information to fulfill the terms 
                    of our contract.<br />
                    <img width='20px' height='20px' src={checkSvg} /> 
                    <b>Legal Obligations:</b> We may disclose your information where we are 
                    legally required to do so in order to comply with applicable law, 
                    governmental requests, a judicial proceeding, court order, or legal 
                    process, such as in response to a court order or a subpoena 
                    (including in response to public authorities to meet national security 
                    or law enforcement requirements).<br />
                    <img width='20px' height='20px' src={checkSvg} /> 
                    <b>Vital Interests:</b> We may disclose your information where we believe
                     it is necessary to investigate, prevent, or take action regarding 
                     potential violations of our policies, suspected fraud, situations 
                     involving potential threats to the safety of any person and illegal 
                     activities, or as evidence in litigation in which we are involved.</p>
                    <p>More specifically, we may need to process your data or share your 
                        personal information in the following situations:</p>
                    <p ><img width='20px' height='20px' src={checkSvg} /> 
                    <b>Business Transfers.</b> We may share or transfer your information 
                    in connection with, or during negotiations of, any merger, sale of 
                    company assets, financing, or acquisition of all or a portion of our 
                    business to another company.</p>
                    </div>
                    <div id="inforetain"><h3>
                        <b>4. HOW LONG DO WE KEEP YOUR INFORMATION?</b></h3></div>
                        <div className="colorBlack contents__link"><p ><i><b>In Short:</b> </i>
                    <i>We keep your information for as long as necessary to 
                    fulfill the purposes outlined in this privacy notice unless 
                    otherwise required by law.</i></p>
                    <p >We will only keep your personal information for as long 
                        as it is necessary for the purposes set out in this 
                        privacy notice, unless a longer retention period is 
                        required or permitted by law (such as tax, accounting 
                        or other legal requirements). No purpose in this notice 
                        will require us keeping your personal information for 
                        longer than 6 months.</p>
                    <p >When we have no ongoing legitimate business need to 
                        process your personal information, we will either delete 
                        or anonymize such information, or, if this is not 
                        possible (for example, because your personal information 
                        has been stored in backup archives), then we will 
                        securely store your personal information and isolate it 
                        from any further processing until deletion is possible.</p>
                    </div>
                    <div id="infosafe"><h3>
                        <b>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</b></h3></div>
                        <div className="colorBlack contents__link"><p ><i><b>In Short:</b></i>
                    <i> We aim to protect your personal information through a system of 
                        organizational and technical security measures.</i></p>
                    <p >We have implemented appropriate technical and organizational 
                        security measures designed to protect the security of any personal 
                        information we process. However, despite our safeguards and efforts 
                        to secure your information, no electronic transmission over the 
                        Internet or information storage technology can be guaranteed to be 
                        100% secure, so we cannot promise or guarantee that hackers, 
                        cybercriminals, or other unauthorized third parties will not be able 
                        to defeat our security, and improperly collect, access, steal, or 
                        modify your information. Although we will do our best to protect your 
                        personal information, transmission of personal information to and 
                        from our Website is at your own risk. You should only access the 
                        Website within a secure environment.</p>
                    </div>
                    <div id="infominors"><h3>
                        <b>6. DO WE COLLECT INFORMATION FROM MINORS?</b></h3></div>
                        <div className="colorBlack contents__link"><p ><i><b>In Short:</b></i><i>We do not knowingly collect data 
                        from or market to children under 18 years of age.</i></p>
                    <p >We do not knowingly solicit data from or market to children 
                        under 18 years of age. By using the Website, you represent that 
                        you are at least 18 or that you are the parent or guardian of such 
                        a minor and consent to such minor dependent’s use of the Website. 
                        If we learn that personal information from users less than 18 
                        years of age has been collected, we will deactivate the account 
                        and take reasonable measures to promptly delete such data from our 
                        records. If you become aware of any data we may have collected from 
                                children under age 18, please contact us at  <a href="mailto:support@iomatters.com" className="link-content">
                            <span className='icon-email' style={{ fontSize: '14px' }}></span> 
                            support@iomatters.com</a> </p>     
                    </div>
                    <div id="privacyrights-7"><h3>
                        <b>7. WHAT ARE YOUR PRIVACY RIGHTS?</b></h3></div>
                        <div className="colorBlack contents__link"><p ><i><b>In Short:</b>In some regions, such as the European 
                    Economic Area (EEA) and United Kingdom (UK), you have rights that 
                    allow you greater access to and control over your personal information. 
                    You may review, change, or terminate your account at any time.</i></p>
                    <p >In some regions (like the EEA and UK), you have certain rights under 
                        applicable data protection laws. These may include the right (i) 
                        to request access and obtain a copy of your personal information, 
                        (ii) to request rectification or erasure; (iii) to restrict the 
                        processing of your personal information; and (iv) if applicable, 
                        to data portability. In certain circumstances, you may also have 
                        the right to object to the processing of your personal information. 
                        To make such a request, please use the contact details
                        provided below. We will consider and act upon any request in 
                        accordance with applicable data protection laws.</p>
                        <p >If we are relying on your consent to process your personal 
                            information, you have the right to withdraw your consent at 
                            any time. Please note however that this will not affect the 
                            lawfulness of the processing before its withdrawal, nor will 
                            it affect the processing of your personal information conducted 
                            in reliance on lawful processing grounds other than consent.
                            If you are a resident in the EEA or UK and you believe we are 
                            unlawfully processing your personal information, you also have 
                            the right to complain to your local data protection supervisory 
                            authority. You can find their contact details here: 
                            <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" 
                                    target="_blank" rel="noopener" className="link-content">
                                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>
                        <p >If you are a resident in Switzerland, the contact details for the data 
                            protection authorities are available here: 
                        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" 
                                    rel="noopener" className="link-content">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>
                    </div>
                    <div id="DNT"><h3><b>8. CONTROLS FOR DO-NOT-TRACK FEATURES</b></h3></div>
                        <div className="colorBlack contents__link"><p >Most web browsers and some mobile operating systems and mobile 
                        applications include a Do-Not-Track («DNT») feature or setting you can 
                        activate to signal your privacy preference not to have data about your 
                        online browsing activities monitored and collected. At this stage no 
                        uniform technology standard for recognizing and implementing DNT signals 
                        has been finalized. As such, we do not currently respond to DNT browser 
                        signals or any other mechanism that automatically communicates your 
                        choice not to be tracked online. If a standard for online tracking is 
                        adopted that we must follow in the future, we will inform you about that 
                        practice in a revised version of this privacy notice.</p>
                    </div>
                    <div id="caresidents"><h3>
                        <b>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b></h3></div>
                        <div className="colorBlack contents__link"><p ><i><b>In Short: </b>Yes, if you are a resident of California, you 
                    are granted specific rights regarding access to your personal information.</i>
                    </p>
                    <p >California Civil Code Section 1798.83, also known as the «Shine The Light»
                        law, permits our users who are California residents to request and obtain
                        from us, once a year and free of charge, information about categories of
                        personal information (if any) we disclosed to third parties for direct 
                        marketing purposes and the names and addresses of all third parties with 
                        which we shared personal information in the immediately preceding calendar
                         year. If you are a California resident and would like to make such a 
                         request, please submit your request in writing to us using the contact 
                         information provided below.<br />
                        If you are under 18 years of age, reside in California, and have a 
                        registered account with the Website, you have the right to request 
                        removal of unwanted data that you publicly post on the Website. 
                        To request removal of such data, please contact us using the contact 
                        information provided below, and include the email address associated 
                        with your account and a statement that you reside in California. We will 
                        make sure the data is not publicly displayed on the Website, but please 
                        be aware that the data may not be completely or comprehensively removed 
                        from all our systems (e.g. backups, etc.).</p>
                    </div>
                        <h4><b>CCPA Privacy Notice</b></h4>
                        <div className="colorBlack contents__link"><p >The California Code of Regulations defines a «resident» as:</p>
                        <p ><img width='20px' height='20px' src={checkSvg} /> 
                        (1) every individual who is in the State of California for other 
                        than a temporary or transitory purpose and<br />
                        <img width='20px' height='20px' src={checkSvg} /> 
                        (2) every individual who is domiciled in the State of California 
                        who is outside the State of California for a temporary or transitory 
                        purpose</p>
                        <p >All other individuals are defined as «non-residents.»<br />
                        If this definition of «resident» applies to you, we must adhere to 
                        certain rights and obligations regarding your personal information.<br />
                         What categories of personal information do we collect?<br />
                        We have collected the following categories of personal information in 
                        the past twelve (12) months:</p></div>
                        <div className="colorBlack contents__link">
                        <table>
                             <tbody> <tr>
                                 <td><strong>Category</strong></td>
                                  <td><strong>Example</strong></td>
                                  <td data-align="center"><strong>Collected</strong></td></tr>
                                 <tr>
                                    <td>A. Identifiers</td>
                                    <td>Contact details, such as real name, alias, postal address, 
                                        telephone or mobile contact number, unique personal identifier,
                                         online identifier, Internet Protocol address, email address and 
                                         account name</td>
                                    <td className="has-text-align-center" data-align="center">NO</td>
                                </tr>
                                <tr>
                                    <td>B. Personal information categories listed in the California Customer 
                                        Records statute</td>
                                    <td>Name, contact information, education, employment, employment history 
                                        and financial information</td>
                                    <td className="has-text-align-center" data-align="center">YES</td>
                                </tr>
                                <tr>
                                    <td>C. Protected classification characteristics under California or 
                                        federal law</td>
                                    <td>Gender and date of birth</td>
                                    <td className="has-text-align-center" data-align="center">NO</td>
                                </tr>
                                <tr>
                                    <td>D. Commercial information</td>
                                    <td>Transaction information, purchase history, financial details and 
                                        payment information</td>
                                    <td className="has-text-align-center" data-align="center">NO</td>
                                </tr>
                                                                                                    <tr>
                                                                                                        <td>E. Biometric information</td>
                                                                                                        <td>Fingerprints and voiceprints</td>
                                                                                                        <td className="has-text-align-center" data-align="center">NO</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>F. Internet or other similar network activity</td>
                                                                                                        <td>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements</td>
                                                                                                        <td className="has-text-align-center" data-align="center">NO</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>G. Geolocation data</td>
                                                                                                        <td>Device location</td>
                                                                                                        <td className="has-text-align-center" data-align="center">NO</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                                                                                                        <td>Images and audio, video or call recordings created in connection with our business activities</td>
                                                                                                        <td className="has-text-align-center" data-align="center">NO</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>I. Professional or employment-related information</td>
                                                                                                        <td>Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us</td>
                                                                                                        <td className="has-text-align-center" data-align="center">NO</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>J. Education Information</td>
                                                                                                        <td>Student records and directory information</td>
                                                                                                        <td className="has-text-align-center" data-align="center">NO</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>K. Inferences drawn from other personal information</td>
                                                                                                        <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                                                                                                        <td className="has-text-align-center" data-align="center">NO</td>
                                                                                                    </tr>
                                                                                                </tbody>
                        </table>
                        </div>
                        <div className="colorBlack contents__link">
                            <p >We may also collect other personal information outside of these 
                            categories instances where you interact with us in-person, online, 
                            or by phone or mail in the context of:</p>
                            <p ><img width='20px' height='20px' src={checkSvg} /> Receiving help 
                            through our customer support channels;<br />
                            <img width='20px' height='20px' src={checkSvg} /> Participation in 
                            customer surveys or contests; and<br />
                            <img width='20px' height='20px' src={checkSvg} /> Facilitation in 
                            the delivery of our Services and to respond to your inquiries.</p>
                        </div>
                        <div className="colorBlack contents__link">
                            <h4><b>How do we use and share your personal information?</b></h4>
                        <p >More information about our data collection and sharing practices 
                            can be found in this privacy notice.</p>
                            <p >You may contact us by email at <a href="mailto:support@iomatters.com" className="link-content">
                            <span className='icon-email' style={{ fontSize: '14px' }}></span>
                                support@iomatters.com</a>, or by referring to the contact 
                            details at the bottom of this document.</p>
                        <p >If you are using an authorized agent to exercise your right to 
                            opt-out we may deny a request if the authorized agent does not 
                            submit proof that they have been validly authorized to act on your 
                            behalf.</p>
                        </div>
                        <div className="colorBlack contents__link"><h4><b>Will your information be shared with anyone else?</b></h4>
                        <p >We may disclose your personal information with our service providers 
                            pursuant to a written contract between us and each service provider. 
                            Each service provider is a for-profit entity that processes the 
                            information on our behalf.</p>
                        <p >We may use your personal information for our own business purposes, 
                            such as for undertaking internal research for technological development 
                            and demonstration. This is not considered to be «selling» of your 
                            personal data.</p>
                        <p >High Project corp has not disclosed or sold any personal information 
                            to third parties for a business or commercial purpose in the preceding 
                            12 months. High Project corp will not sell personal information in 
                            the future belonging to website visitors, users and other consumers.</p>
                        </div>
                        <div className="colorBlack contents__link"><h4><b>Your rights with respect to your personal data</b></h4>
                        <p><u>Right to request deletion of the data — Request to delete</u><br />
                        You can ask for the deletion of your personal information. If you ask us 
                        to delete your personal information, we will respect your request and 
                        delete your personal information, subject to certain exceptions provided 
                        by law, such as (but not limited to) the exercise by another consumer of 
                        his or her right to free speech, our compliance requirements resulting 
                        from a legal obligation or any processing that may be required to protect 
                        against illegal activities.</p>
                        <p ><u>Right to be informed — Request to know</u><br />Depending on the 
                        circumstances, you have a right to know:</p>
                        <p ><img width='20px' height='20px' src={checkSvg} />whether we collect 
                        and use your personal information;<br />
                        <img width='20px' height='20px' src={checkSvg} />the categories of 
                        personal information that we collect;<br />
                        <img width='20px' height='20px' src={checkSvg} />the purposes for which 
                        the collected personal information is used;<br />
                        <img width='20px' height='20px' src={checkSvg} />whether we sell your 
                        personal information to third parties;<br />
                        <img width='20px' height='20px' src={checkSvg} />the categories of 
                        personal information that we sold or disclosed for a business purpose;<br />
                        <img width='20px' height='20px' src={checkSvg} />the categories of third 
                        parties to whom the personal information was sold or disclosed for a business 
                        purpose; and<br />
                        <img width='20px' height='20px' src={checkSvg} />the business or commercial 
                        purpose for collecting or selling personal information.</p>
                        <p >In accordance with applicable law, we are not obligated to provide or 
                            delete consumer information that is de-identified in response to a 
                            consumer request or to re-identify individual data to verify a consumer 
                            request.</p>
                        <p ><u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy 
                            Rights</u><br />
                         We will not discriminate against you if you exercise your privacy rights.</p>
                         <p ><u>Verification process</u><br />
                         Upon receiving your request, we will need to verify your identity to 
                         determine you are the same person about whom we have the information in 
                         our system. These verification efforts require us to ask you to provide 
                         information so that we can match it with information you have previously 
                         provided us. For instance, depending on the type of request you submit, 
                         we may ask you to provide certain information so that we can match the 
                         information you provide with the information we already have on file, or 
                         we may contact you through a communication method (e.g. phone or email) 
                         that you have previously provided to us. We may also use other verification 
                         methods as the circumstances dictate.<br />
                         We will only use personal information provided in your request to verify 
                         your identity or authority to make the request. To the extent possible, 
                         we will avoid requesting additional information from you for the purposes 
                         of verification. If, however, we cannot verify your identity from the 
                         information already maintained by us, we may request that you provide 
                         additional information for the purposes of verifying your identity, and 
                         for security or fraud-prevention purposes. We will delete such additionally 
                         provided information as soon as we finish verifying you.</p>
                         <p ><u>Other privacy rights</u><br />
                         <img width='20px' height='20px' src={checkSvg} />you may object to the 
                         processing of your personal data<br />
                         <img width='20px' height='20px' src={checkSvg} />you may request correction 
                         of your personal data if it is incorrect or no longer relevant, or ask 
                         to restrict the processing of the data<br />
                         <img width='20px' height='20px' src={checkSvg} />you can designate an 
                         authorized agent to make a request under the CCPA on your behalf. We may 
                         deny a request from an authorized agent that does not submit proof that 
                         they have been validly authorized to act on your behalf in accordance 
                         with the CCPA.<br />
                         <img width='20px' height='20px' src={checkSvg} />you may request to 
                         opt-out from future selling of your personal information to third parties. 
                         Upon receiving a request to opt-out, we will act upon the request as 
                         soon as feasibly possible, but no later than 15 days from the date of 
                         the request submission.<br />
                         To exercise these rights, you can contact us by 
                         email at  <a href="mailto:support@iomatters.com" className="link-content">
                            <span className='icon-email' style={{ fontSize: '14px' }}></span> 
                            support@iomatters.com</a>      
                        or by referring to the contact details at the bottom of this document. 
                        If you have a complaint about how we handle your data, we would like 
                        to hear from you.</p>
                    </div>
                    <div id="policyupdates"><h3>
                        <b>10. DO WE MAKE UPDATES TO THIS NOTICE?</b></h3></div>
                        <div className="colorBlack contents__link">
                        <p ><i><b>In Short:</b> Yes, we will update this notice as necessary 
                        to stay compliant with relevant laws.</i></p>
                        <p >We may update this privacy notice from time to time. The updated 
                        version will be indicated by an updated «Revised» date and the updated 
                        version will be effective as soon as it is accessible. If we make material
                        changes to this privacy notice, we may notify you either by prominently 
                        posting a notice of such changes or by directly sending you a notification.
                        We encourage you to review this privacy notice frequently to be informed of 
                        how we are protecting your information.</p>
                    </div>
                    <div id="contact-11"><h3>
                        <b>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b></h3></div>
                        <div className="colorBlack contents__link"><p >If you have questions or comments about this notice, you may email 
                            us at <br /><a href="mailto:support@iomatters.com" className="link-content">
                            <span className='icon-email' style={{ fontSize: '14px' }}></span> 
                            support@iomatters.com</a> or by post to:</p>
                            <p>6608 DuVon Pl, Manassas, 20111, VA</p>
                    </div>
                    <div id="request"><h3>
                        <b>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</b></h3></div>
                        <div className="colorBlack contents__link"><p >Based on the applicable laws of your country, you may have the right to 
                        request access to the personal information we collect from you, change that 
                        information, or delete it in some circumstances. To request to review, update,
                         or delete your personal information, please submit a request form by clicking
            <a href="https://app.termly.io/notify/e3589f56-1917-4dee-9b04-61d3cd1bde3e" target="_blank" 
                                rel="noopener" className="link-content"> here</a>.</p>
             </div>
                        <div >
                            <button onClick={() => props.setTrigger(false)}>Close it
                            </button>
                            <p></p>
                        </div>
                        <p></p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    ) : '';
}

export default PopupPrivacyPolicy;