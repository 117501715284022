import React from 'react';

const PriceForFullScreen = ()=>{
    return(
        <div className="centered tabl-full">
    <div className="row-fluid line-bottom">
        <div className='span3'> <p></p></div>
        <div className='span3'> <strong className="strong-price">Characteristics</strong></div>
        <div className='span3'> <strong className="strong-price">Number of Databases</strong></div>
        <div className='span3'> <strong className="strong-price">Annual Cost</strong></div>
    </div>
            <div className="row-fluid line-bottom">
                <div className='span3 price-column price-column-full pad-btm'> 
            <h2>Small</h2></div>
        <div className='span3' style={{paddingTop:'3%',textAlign:'left'}}> <ul>
                    <li>less than 500GB in size</li>
                    <li>less than 10K tps</li>
        </ul></div>
        <div className='span3'> 
            <div className="row-fluid" style={{borderBottom: "1px solid #747C89", paddingTop:'21px'}}>Up to 5</div>
            <div className="row-fluid" style={{borderBottom: "1px solid #747C89", paddingTop:'22px'}}>Up to 10</div>
            <div className="row-fluid" style={{borderBottom: "1px solid #747C89", paddingTop:'22px'}}>Up to 20</div>
            <div className="row-fluid" style={{borderBottom: "none", paddingTop:'22px'}}>Over 20</div>
        </div>
        <div className='span3'> 
            <div className="row-fluid price-number" style={{borderBottom: "1px solid #747C89", paddingTop:'10px'}}>$11,000</div>
            <div className="row-fluid price-number" style={{borderBottom: "1px solid #747C89", paddingTop:'10px'}}>$19,000</div>
            <div className="row-fluid price-number" style={{borderBottom: "1px solid #747C89", paddingTop:'10px'}}>$26,000</div>
            <div className="row-fluid price-number"style={{borderBottom: "none", paddingTop:'12px'}} >$1000 <span>per DB</span></div>
        </div>
    </div>
            <div className="row-fluid line-bottom">
                <div className='span3 price-column price-column-full'> 
            <h2>Medium</h2></div>
        <div className='span3'style={{paddingTop:'3%',textAlign:'left'}}> <ul >
                    <li>500GB - 10TB  in size</li>
                    <li>10K - 20K tps</li>
        </ul></div>
        <div className='span3 pad-top'> <p style={{color:"#747C89"}}>1</p></div>
        <div className='span3 pad-top'style={{textAlign:'left'}}> 
            <span className='price-number' >$20,000</span></div>
    </div>
    <div className="row-fluid">
                <div className='span3 pad-top price-column price-column-full'> <h2>Large</h2></div>
        <div className='span3 pad-top' style={{textAlign:'left'}}><ul >
                    <li>10TB+ in size</li>
                    <li>20K+ tps</li>
        </ul></div>
        <div className='span3 pad-top centered'> <p style={{color:"#747C89"}}>1</p></div>
        <div className='span3 pad-top'style={{ textAlign:'left'}}> 
            <span className='price-number'>$30,000</span></div>
    </div>
</div>
    )
}
export default PriceForFullScreen;