import React from 'react';

const ServiceUno = (props) => {
    return ( 
    <div className="span4">
        <div className="centered service">
            <div className="circle-border zoom-in">
                <img className="img-circle" src={props.img} alt={props.title}/>
            </div>
            <h3>{props.title}</h3>
            <p>{props.skills}</p>
        </div>
    </div>
     );
}
 
export default ServiceUno;