import { useEffect,createRef } from "react";
import checkSvg from './../../images/check-svg.svg'

const CaseStudies = () => {
    const refComponent = createRef();
    useEffect(() => {
        const height = refComponent.current.getBoundingClientRect().height;
        const caseElems = document.getElementsByClassName("setHeight");
        for (let i = 0; i < caseElems.length; i++) {
            caseElems[i].style.height= height+'px';
        }
      }, [refComponent]);

    return ( 
        <div id="clients">
            <div className="section primary-section" id="CaseStudiesSection">
                <div className="triangle animated fadeInDown"></div>
                <div className="container">
                    <div className="title">
                        <h1>Case Studies</h1>
                    </div>
                    <div className="row-fluid">
                        <div className="span3">
                            <div className="testimonial setHeight">
                                <p className="centered"><strong className="strong-price"> 15+Years</strong>
                                    <p>of Experience </p></p>
                            </div>
                        </div>

                        <div className="span3">
                            <div className="testimonial setHeight">
                                <p className="centered"><strong className="strong-price"> 83+ Projects</strong>
                                    <p> Successfully Delivered</p></p>                                        
                            </div>
                        </div>

                        <div className="span3">
                            <div className="testimonial setHeight">
                                <p className="centered"><strong className="strong-price"> 18+ Certified</strong>
                                    <p>Engineers </p></p>
                            </div>
                        </div>

                        <div className="span3">
                            <div className="testimonial" ref={refComponent}>
                                <p className="centered"><strong className="strong-price"> 740 TB</strong>
                                    <p>of Databases size in total</p></p>
                            </div>
                        </div>

                    </div>

                    <div className="row row-case">
                        <div className="case-img">
                            <div className="case-img-before">
                            <div className="case-img-height"></div>
                            <img width='20px' height='20px' src={checkSvg} /></div>
                        <div className="span12">
                                <p className="case-text"><strong>
                                    50+ Highly Available PostreSQL Clusters </strong>
                                    serving critical mission databases for a large  insurance conpamy.</p>
                            </div></div>
                        <div className="case-img">
                            <div className="case-img-before">
                                <div className="case-img-height"></div>
                                <img width='20px' height='20px' src={checkSvg} /></div>
                        <div className="span12">
                                <p className="case-text">Delivered multiple 
                                    <strong> Cloud migrations, Cross-Cloud replication, IaC Database deployments, </strong>
                                    leveraging PostgreSQL, MSSQL, Oracle and MySQL.</p>                                      
                         </div></div>
                            <div className="case-img">
                                <div className="case-img-before">
                                    <div className="case-img-height"></div>
                                    <img width='20px' height='20px' src={checkSvg} /></div>
                        <div className="span12">
                                <p className="case-text">Designed and set up 
                                <strong> Multi-Terabyte DWH and ETL systems, </strong>
                                leveraging data partitioning, data cubes and BI tools.</p>                                      
                            </div></div>
                                <div className="case-img">
                                    <div className="case-img-before">
                                        <div className="case-img-height"></div>
                                        <img width='20px' height='20px' src={checkSvg} /></div>
                        <div className="span12">
                                <p className="case-text">Moved <strong>Hadoop/HDFS Cluster </strong>
                                to a newer datacenter through expanding it as a Geo-Cluster and switching over with zero downtime.</p>                                      
                            </div></div>
                                    <div className="case-img">
                                        <div className="case-img-before">
                                            <div className="case-img-height"></div>
                                            <img width='20px' height='20px' src={checkSvg} /></div>
                        <div className="span12">
                                <p className="case-text"> <strong>MSQL-to-PostgreSQL & Oracle-to-PostgreSQL  </strong>
                                schemas and data migrations are done for several customers to bring significant cost savings.</p>                                      
                            </div></div>
                                        <div className="case-img">
                                            <div className="case-img-before">
                                                <div className="case-img-height"></div>
                                                <img width='20px' height='20px' src={checkSvg} /></div>
                        <div className="span12">
                                <p className="case-text"><strong>MariaDB Galera Cluster (multi-master)</strong>
                                was implemented for a client hosting a critical mission application..</p>                                      
                            </div></div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default CaseStudies;