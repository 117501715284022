
const TeamUno = (props) => {
    return ( 
    <div className="span2" id={props.id}>
        <div className="thumbnail">
            <img src={props.img} alt={props.title} />
                <div className="team-uno">
                    <div className="team-uno-title"><h4>{props.title}</h4></div>
                    <div className="team-uno-skills">
            <h5 style={{ color: "white"}}>{props.skills}</h5></div></div>
            {/*<div className="mask">
                <h2></h2>
                <p></p>
    </div>*/}
        </div>
    </div>

     );
}
 
export default TeamUno;