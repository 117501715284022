import React from 'react';

const ExpertiseUno = (props) => {
    return ( 
        <div id={props.key} className='expertise-li'>
            
                <img src={props.img} alt={props.title} className='expertise-img'/>
            
        </div>
     );
}
 
export default ExpertiseUno;