import { useState } from 'react';

import { DefaultPlayer as Video } from 'react-html5video';
import TextSlider from './TextSlider';
import { TextSliderList } from "../../helpers/TextSliderList";
import PopupContact from '../../components/Popups/PopupContact';

import SupportImg from './../../images/Support3-3.png';
import videoTest3 from './../../video/dba1.webm';
import VideoBg from './../../images/Slider.png';

const HomeSection = () => {
    const [PopupOpen, setPopupOpen]=useState(false);
    return (  
        <div id="HomeSection"> {/* Start home section */}

            {/* Start Slider */}
            <div id="da-slider" className="da-slider">
                <Video autoPlay loop muted controls={{}} poster={VideoBg} className="main_video_bg" id="main_video_bg">

                <source src={videoTest3} type="video/webm" />
                </Video>
                <div className="triangle animated fadeInDown"></div>
                {/* mask elemet use for masking background image */}
                <div className="mask"></div>
                {/* All slides centred in container element */}
                <div className="container">
                    {/* Start first slide */}
                    <div className="da-slide da-slide-current">
                        <h2>Remote & Cloud <br />
                                                Database Administration Services</h2>
                       <p> We reduce costs by up to 50% on databases management.
                            We bring an expert-level mindset and years of experience to tackle every challenge.
                            We provide you a Peace Of Mind.</p>
                       <TextSlider texts={TextSliderList}/>
                        <div className="da-img colum-table">
                            <img src={SupportImg} alt="image01" width="220"/>
                            <a className="homebutton" onClick={()=> setPopupOpen(true)}>Let's Talk</a>
                        </div>
                        {PopupOpen &&(<PopupContact trigger={PopupOpen} setTrigger={setPopupOpen} 
                            onClose={()=>setPopupOpen(false)} />
                        )}

                    </div>
                </div>
            </div> {/* End home section */}
        </div>
       
    );
}
 
export default HomeSection;