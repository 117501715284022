const TextSliderList=[
    {
    boldText:'24/7 round-the-clock',
    simplText:'support',
    },
    {
        boldText:'Cutting',
        simplText:'costs by up to 50%',
    },
    {
        boldText:'IMMEDIATE ACCESS',
        simplText:' TO EXPERTISE'
    }
    
]
export {TextSliderList};