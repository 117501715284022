import React from 'react';
import { useEffect, useState, createRef } from 'react';


const PriceForSmlScreen = ()=>{
    const refComponent = createRef();
    const [heightPrice, setHeightPrice]=useState('550px');
      
  useEffect(() => {
    const height = refComponent.current.getBoundingClientRect().height;
    setHeightPrice(height+'px');
  }, [refComponent]);

    return(
        <div className="price-table row-fluid">
        <div className="span4 price-column" ref={refComponent}>
            <h3>Small</h3>
            <ul className="list">
            <li>
            <div className="row-fluid db-num" >
                <div className="span6 left-text" ><strong className="strong-price">Annual Cost</strong></div>
                <div className="span6 right-text"><strong className="strong-price">Num of DB</strong></div>
            </div>
            <div className="row-fluid db-num" >
                <div className="span6 price-number left-text" >$11,000</div>
                <div className="span6 right-text"><span >Up to 5</span></div>
            </div>
            <div className="row-fluid db-num" >
                <div className="span6 price-number left-text">$19,000</div>
                <div className="span6 right-text"><span>Up to 10</span></div>
            </div>
            <div className="row-fluid db-num" >
                <div className="span6 price-number left-text">$26,000</div>
                <div className="span6 right-text"><span >Up to 20</span></div>
            </div>
            <div className="row-fluid db-num">
                <div className="span6 left-text"><nobr>
                    <span className='price-number '>$1000 </span><span>per DB</span></nobr></div>
                <div className="span6 right-text"><span>Over 20</span></div>
            </div>
            </li>
                <li>
                    <div className='row-fluid span4 '>
                    <div>
                    <strong className="strong-price centred">Characteristics</strong></div>
                    <div className="left-text"><ul className='price-list-add'><li>less than 500GB in size </li>
                     <li>less than 10K tps</li></ul></div></div>
                   

                </li>
            </ul>
        </div>
        <div className="span4 price-column" style={{height:heightPrice}}>
            <h3>Medium</h3>
            <ul className="list">
            <li><div className="row-fluid db-num" >
                <div className="span6 left-text" ><strong className="strong-price">Annual Cost</strong></div>
                <div className="span6 right-text"><strong className="strong-price">Num of DB</strong></div>
            </div>
            <div className="row-fluid db-num" >
                <div className="span6 price-number">$20,000</div>
                <div className="span6 right-text"><span>1</span></div>
            </div>
            </li>
            <li>
            <div className='row-fluid span4 '>
                <div>
                <strong className="strong-price centred">Characteristics</strong></div>
                <div className="left-text"><ul className='price-list-add'><li>500GB - 10TB  in size </li>
                    <li>10K - 20K tps</li></ul></div></div>
            </li>
            </ul>
        </div>
        <div className="span4 price-column" style={{height:heightPrice}}>
            <h3>Large</h3>
            <ul className="list">
            <li><div className="row-fluid db-num" >
                <div className="span6 left-text" ><strong className="strong-price">Annual Cost</strong></div>
                <div className="span6 right-text"><strong className="strong-price">Num of DB</strong></div>
            </div>
            <div className="row-fluid" >
                <div className="span6 price-number" >$30,000</div>
                <div className="span6 right-text"><span>1</span></div>
            </div>
            </li>
                <li>
                <div className='row-fluid span4 '>
                <div>
                <strong className="strong-price centred">Characteristics</strong></div>
                <div className="left-text">
                    <ul className='price-list-add'><li>510TB+ in size </li>
                    <li>20K+ tps</li></ul></div></div>
                </li>
            </ul>
        </div>
    </div>
    )
}
export default PriceForSmlScreen;