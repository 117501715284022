import React from 'react';
import { useState, useEffect } from 'react';
import MobilNavBar from './MobilNavBar';
import logo from './../../images/Logo-Anton-sml9.png';

/**
 * @param {number} currentPosition Current Scroll position
 * @param {Array} sectionPositionArray Array of positions of all sections
 * @param {number} startIndex Start index of array
 * @param {number} endIndex End index of array
 * @return {number} Current Active index
 */

const nearestIndex = (
    currentPosition,
    sectionPositionArray,
    startIndex,
    endIndex
  ) => {
    if (startIndex === endIndex) return startIndex;
    else if (startIndex === endIndex - 1) {
      if (
        Math.abs(
          sectionPositionArray[startIndex].headerRef.current.offsetTop -
            currentPosition
        ) <
        Math.abs(
          sectionPositionArray[endIndex].headerRef.current.offsetTop -
            currentPosition
        )
      )
        return startIndex;
      else return endIndex;
    } else {
      var nextNearest = ~~((startIndex + endIndex) / 2);
      var a = Math.abs(
        sectionPositionArray[nextNearest].headerRef.current.offsetTop -
          currentPosition
      );
      var b = Math.abs(
        sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop -
          currentPosition
      );
      if (a < b) {
        return nearestIndex(
          currentPosition,
          sectionPositionArray,
          startIndex,
          nextNearest
        );
      } else {
        return nearestIndex(
          currentPosition,
          sectionPositionArray,
          nextNearest,
          endIndex
        );
      }
    }
  };


const  MainNavBar = ({navHeader}) => {

  const [isOpen, setOpen]=useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
      const handleScroll = (e) => {
        var index = nearestIndex(
          window.scrollY,
          navHeader,
          0,
          navHeader.length - 1
        );
        setActiveIndex(index);
      };
      document.addEventListener("scroll", handleScroll);
      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    }, []);
    
    return (  
            <div className="navbar navbar-fixed-top">
                <div className="navbar-inner">
                    <div className="container">
                      <a href="#HomeSection" className="brand">
                            <img src={logo} width="120" height="40" alt="Logo" />
                            {/* This is website logo */}
                        </a>
                        {/* Navigation button, visible on small resolution */}
                       <MobilNavBar  navHeader={navHeader} activeIndex={activeIndex}/>
                         {/*<button type="button" className="btn btn-navbar" onClick={()=>setOpen(!isOpen)}data-toggle="collapse" data-target=".nav-collapse">
                          <i className="icon-menu"></i>
                        </button>*/}
                        {/* Main navigation */}
                        <div className="nav-collapse collapse pull-right desk-menu">
                        <ul className="nav" id="top-navigation">
                             {navHeader.map((header, index) => (
                                 <li
                                key={index + header.headerID}
                                className={ activeIndex === index ? 'active' : '' }>
                                <a href={`#${header.headerID}`}> {header.headerTitle}</a>
                                </li>
                               ))}
                            </ul>
                        </div>
                        {/* End main navigation */}
                    </div>
                </div>
            </div>
        );
}
 
export default MainNavBar;
