import React from 'react';
import processFirst from './../../images/team/screen_2.svg';
import processSecond from './../../images/team/NDA_2.svg';
import processThird from './../../images/team/audit_2.svg';
import processForth from './../../images/team/contract_2.svg';
import processFifs from './../../images/team/roadmap_2.svg';
import processSix from './../../images/team/support_2.svg';
import arrowRight from './../../images/team/Arrow-Right_2.svg';
import arrowTop from './../../images/team/Arrow-Top_2.svg';
import arrowDown from './../../images/team/Arrow-Down_2.svg';
import arrowDiagonal from'./../../images/team/Arrow-Diogonal.svg';

const ProcessFullScreen = () =>{
    return (
        <div className="centered container">
            <div className="row-fluid row-process">
                <div className="span2">
                    <div className="centered zoom-in">
                        <img className="" src={processFirst} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered">
                    </div>
                </div>
                <div className="span2">
                    <div className="centered zoom-in">
                        <img className="" src={processThird} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered">
                        
                    </div>
                </div>
                <div className="span2">
                    <div className="centered zoom-in">
                        <img className="" src={processFifs} />
                    </div>
                </div>
            </div>
            <div className="row-fluid row-process">
                <div className="span2">
                    <div className="centered">
                        <img className="proccess-img" src={arrowDown} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered">
                        <img className="img-diagonal" src={arrowDiagonal} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered">
                        <img className="proccess-img" src={arrowDown} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered ">
                        <img className="img-diagonal" src={arrowDiagonal} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered">
                        <img className="proccess-img" src={arrowDown} />
                    </div>
                </div>
            </div>
            <div className="row-fluid row-process">
                <div className="span2">
                    <div className="centered zoom-in">
                        <img className="" src={processSecond} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered">
                        
                    </div>
                </div>
                <div className="span2">
                    <div className="centered zoom-in">
                        <img className="" src={processForth} />
                    </div>
                </div>
                <div className="span2">
                    <div className="centered">
                    </div>
                </div>
                <div className="span2">
                    <div className="centered zoom-in">
                        <img className="" src={processSix} />
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default ProcessFullScreen;