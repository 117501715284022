import Expertiseimg1 from './../images/expertises/oracle.svg';
import Expertiseimg2 from './../images/expertises/microsoft-sql-server-logo.svg'
import Expertiseimg3 from './../images/expertises/mysql.svg';
import Expertiseimg4 from './../images/expertises/mariadb-logo-vert_blue-svg.svg';
import Expertiseimg5 from './../images/expertises/postgresql.svg';
import Expertiseimg6 from './../images/expertises/hardoop.svg';
import Expertiseimg7 from './../images/expertises/NoSQL.svg';


const ExpertiseList=[
    {
        key:'1-expertise',
        title:"Oracle",
        img: Expertiseimg1,
        urlorg:"",
    },
    {
        key:'2-expertise',
        title:"SQL",
        img: Expertiseimg2,
        urlorg:"",
    },
    {
        key:'3-expertise',
        title:"Mysql",
        img: Expertiseimg3,
        urlorg:"",
    },
    {
        key:'4-expertise',
        title:"MariaDB",
        img: Expertiseimg4,
        urlorg:"",
    },
    {
        key:'5-expertise',
        title:"PostgreSQL",
        img: Expertiseimg5,
        urlorg:"",
    },
    {
        key:'6-expertise',
        title:"Hardoop",
        img: Expertiseimg6,
        urlorg:"",
    },
    {
        key:'7-expertise',
        title:"NoSQL",
        img: Expertiseimg7,
        urlorg:"",
    }
]
export {ExpertiseList};