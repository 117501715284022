import { useEffect, createRef, useRef } from "react";
import ContactForm_new from './ContactForm_new';

function PopupContact(props){
    const popupRef = useRef(null);

    const handleClickOutside = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            props.setTrigger(false);
        }
    };
    const refComponent = createRef();
    useEffect(() => {
        const height = props.trigger?refComponent.current.getBoundingClientRect().height:0;
        const layerElem = document.getElementsByClassName("inner-lay");
        if (height>0){
          layerElem[0].style.height = (height+20) + 'px';
        }
    }, [refComponent]);


return (props.trigger)?(
    <div className="overlay" onClick={handleClickOutside}>{/*onClick={() => props.setTrigger(false)}>*/}
        <div ref={popupRef} className="inner-lay animated pulse contact">
        <div className="container">
            <div className="row-fluid">
                    <div className="contact-form" id="contact-form" ref={refComponent}>                        
                        <div className='position-close-btn'>
                            <button className="close-btn" onClick={() => props.setTrigger(false)}><span className='icon-cancel' ></span>
                            </button>
                        </div>
                        <ContactForm_new/>
                </div>
            </div>
        </div>
    </div>
</div>
):'';
}

export default PopupContact;