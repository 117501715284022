import { TeamList } from '../../helpers/TeamList';
import TeamUno from '../../components/Team/TeamUno';
import Flag from './../../images/Flag.svg';

const Team = () => {

    return ( 
       <div className="section primary-section" id="about"> {/* About us section start */}
            <div className="triangle animated fadeInDown"></div>
            <div className="container">
                <div className="title">
                    <h1>Core Team</h1>
                    <p><img src={Flag} width={'60px'} /></p>
                </div>
                <div className="row-fluid team">
                <div className="span1" ></div>
                    {TeamList.map((member) => {
                        return(
                            
                            <TeamUno id={member.id} title={member.title} 
                                    img={member.img} skills={member.skills} 
                                    
                            />
                        )
                    })}
                </div>
                <div className="about-text centered">
                    <h3>Engineering Team</h3>
                    <p>Our Support Engineers and DBA experts work in shifts 
                        to cover 24x7x365, ready to assist and mitigate 
                        any concern at any time. With our comprehensive 
                        support system in place, there is no longer a need 
                        to hire dedicated DBAs, saving you valuable resources 
                        on recruitment and staffing. You can immediately access 
                        our expertise and benefit from round-the-clock support 
                        without incurring additional costs. Focus on your core 
                        business activities while we handle the technical 
                        intricacies, ensuring smooth operations and peace of
                         mind.</p>
                    
                </div>

            </div>{/* About us section end */}
        </div>
        
     );
}
 
export default Team;