import { useState } from "react";

const  MobilNavBar = ({navHeader, activeIndex}) => {
    const [isOpen, setOpen]=useState(false);
 return(
    <>
        <button type="button" className="btn btn-navbar" onClick={()=>setOpen(!isOpen)}data-toggle="collapse" data-target=".nav-collapse">
            <i className="icon-menu"></i>
        </button>
       {/* <!-- Navigation -->*/}
       <div className="nav-collapse collapse pull-right mobil-menu" style={isOpen? {height: "auto"}: {height: "0px"}}>
            <ul className="nav" id="top-navigation">
                {navHeader.map((header, index) => (
                <li
                    key={index + header.headerID}
                     className={ activeIndex === index ? 'active' : '' }>
                    <a href={`#${header.headerID}`} onClick={()=>setOpen(!isOpen)}> {header.headerTitle}</a>
                </li>
                ))}
            </ul>
        </div>
        {/*<!-- End Navigation -->*/}
    </>
 )   
}
export default MobilNavBar;