import React from 'react';
import { useForm } from 'react-hook-form';

const ContactForm_new = () => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            xnQsjsdp: 'e9cb15f8e3924c2064afb16e888a93343d29192d81ac1d3cd1d30cb41cb81e84',
            xmIwtLD: '65076a5f5b5fbab28606fd006e57d109867b2c80797f9e8143d6551c3bb7516715512f5c1d5236488d6acccdbdfa1198'
        }
    });

    const onSubmit = async (data) => {
        const formData = {
            "Last Name": data.lastName,
            "Email": data.email,
            "Description": data.commit,
            "xnQsjsdp": data.xnQsjsdp,
            "zc_gad": data.zc_gad,
            "xmIwtLD": data.xmIwtLD,
            "actionType": data.actionType,
            "returnURL": data.returnURL

        };
        let requestBody = `Last%20Name=${encodeURIComponent(formData["Last Name"])}&Email=${encodeURIComponent(formData.Email)}&Description=${encodeURIComponent(formData.Description)}&xnQsjsdp=${encodeURIComponent(formData.xnQsjsdp)}&zc_gad=${encodeURIComponent(formData.zc_gad)}&xmIwtLD=${encodeURIComponent(formData.xmIwtLD)}&actionType=TGVhZHM%3D&returnURL=${encodeURIComponent(formData.returnURL)}&te=${encodeURIComponent(data.te)}&wbfIanaFrD=${encodeURIComponent(data.wbfIanaFrD)}&webform_analytics_submission=${encodeURIComponent(data.webform_analytics_submission)}&rw=${encodeURIComponent(data.rw)}&la=${encodeURIComponent(data.la)}&eo=${encodeURIComponent(data.eo)}`;
        try {
            const response = await fetch('https://crm.zoho.com/crm/WebToLeadForm', {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: requestBody
            });
            if (!response.ok) {
                throw new Error('Failed to submit form');
            }

            {
                console.log('Form submitted successfully');
                const splashinfodom = document.getElementById('successSend');
                splashinfodom.style.display = 'block';
                setTimeout(function () {
                    splashinfodom.style.display = 'none';
                }, 5000);
                reset();
            }
        } catch (error) {
            console.error('Error submitting form:', error.message);
            const splashinfodomError = document.getElementById('errorSend');
            splashinfodomError.style.display = 'block';
            setTimeout(function () {
                splashinfodomError.style.display = 'none';
            }, 7000);
        }
    };

    return (
        <>
            <div id="successSend" className="alert alert-success invisible">
                <strong>Well done!</strong>Your message has been sent.</div>
            <div id="errorSend" className="alert alert-error invisible">There was an error.</div>
            <div className=''><h3>Get In Touch</h3></div>
            <div id='crmWebToEntityForm' className=''>
                <form id='webform' name='WebToLeads6120390000000450569' acceptCharset='UTF-8' onSubmit={handleSubmit(onSubmit)}>
                    <input
                        {...register("xnQsjsdp")}
                        type="hidden"
                        value='e9cb15f8e3924c2064afb16e888a93343d29192d81ac1d3cd1d30cb41cb81e84'
                    />
                    <input
                        {...register("zc_gad")}
                        type="hidden"
                        value=''
                    />
                    <input
                        {...register("xmIwtLD")}
                        type="hidden"
                        value='65076a5f5b5fbab28606fd006e57d109867b2c80797f9e8143d6551c3bb7516715512f5c1d5236488d6acccdbdfa1198'
                    />
                    <input
                        {...register("actionType")}
                        type="hidden"
                        value='TGVhZHM='
                    />
                    <input
                        {...register("returnURL")}
                        type="hidden"
                        value='null'
                    />


                    <div className="control-group">
                        <div className="controls">
                            <input
                                {...register("lastName", { required: "Please fill the Name", pattern: /^[^0-9]+$/ })}
                                className="span12"
                                type="text"
                                id="lastName"
                                placeholder="* Your name..."
                                maxLength="80"
                            />
                            {errors.lastName && errors.lastName.type === 'required' && <div className="error left-align" id="err-name">{errors.lastName.message}</div>}
                            {errors.lastName && errors.lastName.type === 'pattern' && <div className="error left-align" id="err-name">Please enter a valid name</div>}
                        </div>
                    </div>

                    <div className="control-group">
                        <div className="controls">
                            <input
                                {...register("email", { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                className="span12"
                                placeholder="* Your email..."
                                id="email"
                                type="email"
                                maxlength="100" />
                            {errors.email && errors.email.type === 'required' && <div className="error left-align" id="err-email">"Please fill the email"</div>}
                            {errors.email && errors.email.type === 'pattern' && <div className="error left-align" id="err-email">Please enter a valid email address</div>}

                        </div>
                    </div>

                    <div className="control-group">
                        <div className="controls">
                            <input className="span12" type="number"
                                minLength="10"
                                name="phone" id="phone" placeholder="Your phone number..."
                                {...register("Phone")}
                            />
                        </div>
                    </div>

                    <div className="control-group">
                        <div className="controls">
                            <textarea
                                {...register("commit", { required: "Please enter your comment." })}
                                placeholder="* Comments..."
                                id="commit"
                                className="span12"
                            ></textarea>
                            {errors.commit && <div className="error left-align" id="err-comment">{errors.commit.message}</div>}
                        </div>
                    </div>
                    <div className="control-group">
                        <div className="controls centered">
                            <input type='submit' id='formsubmit' className='message-btn'
                                value='Send&#x20;Enquiry' title='Send&#x20;Enquiry' />
                        </div>
                    </div>
                    <input
                        {...register("te")}
                        type="hidden"
                        value='true'
                    />
                    <input
                        {...register("wbfIanaFrD")}
                        type="hidden"
                        value='tra8a024e7e9b69be5d2d477953527f708eaab090219f04336dc1777031cd2fab524891c6dd45a06220bd6bc1dc9540f8bue'
                    />
                    <input
                        {...register("webform_analytics_submission")}
                        type="hidden"
                        value=''
                    />
                    <input
                        {...register("rw")}
                        type="hidden"
                        value='033ab59a629e9448ddc22efad6ef92c1964a534e39f2ebd04394af771fb865cd'
                    />
                    <input
                        {...register("la")}
                        type="hidden"
                        value='09b972afa94de5b98fb9176ffa1ecdae64f68c46d7df7663562ed45758533391'
                    />
                    <input
                        {...register("eo")}
                        type="hidden"
                        value='e448a7a3b26678a02cd66ee42d4c45e1e30c1d05bf12d1fd981374a117373bb6'
                    />

                    <script id='wf_anal'
                        src='https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=ba6b1099ffdcdd53d4728cb6677f3349ed77974beddba30c2c3bb3972f053fdd2e2e690e284e9407cf4ae3a5b0116d63gid2727866573d11fd05485c9a1cc1016546dd85c9641e89b54334761bbf247e4d5gidce78da982ad3c0b117416988bff8ca5c7d5160867f61e2d4fffcf0c364ff3cfegid66a39c7c1fe25929741694e7af9148befb0663433846089acca75a0a4a37fb7b&tw=d4231952ffb7edc7c750e39ad64365b8575716b2404c516a8247df06a241c71b'></script>
                </form>
            </div>
        </>
    )
}

export default ContactForm_new;