import ContactForm_new from '../../components/Popups/ContactForm_new';

const ContactUs = () => {

    return (  
        <>{/*  <div  className="contact">Contact section start */} 
            <div className="section primary-section" id="ContactUsSection" >
                <div className="triangle"></div>  
            <div className='contact'>
            <div className='mask-contact'>
                <div className="container ">
                    <div className="title">
                        <h1>Contact Us</h1>
                        </div>
                </div>
                <div className="container">
                    <div className="row-fluid">
                                <div className="span12 contact-form centered animated fadeInDown" >{/*ref={refComponent}>*/}
                                    <ContactForm_new />
                            </div>                        
                    </div>
                    </div>
                </div>
            </div>
         </div>{/* Contact section edn   </div>*/}
       </> 
    );
}
 
export default ContactUs;