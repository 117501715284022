import { ExpertiseList } from '../../helpers/ExpertiseList';
import ExpertiseUno from '../../components/Team/ExpertiseUno';
import ProcessFullScreen from './ProcessesFullScreen';

const Expertises = () => {
    return ( 
            <div className="section secondary-section ">
            <div className="triangle"></div>
            <div className="mask-bg">
                <div className=" centered">
                    {/* container <div className="sub-section">*/}
                        <div className="title expertises-title">
                            
                                <h3>Our Expertise</h3>
                            
                        </div>
                        <div className="row client-slider items-wrap" id="clint-slider">
                            <div className='items marquee'>
                        {ExpertiseList.map((expertise) => {
                        return(
                            
                            <ExpertiseUno key={expertise.key} title={expertise.title} img={expertise.img} urlorg={expertise.urlorg} />
                        )
                    })}
                    </div>
                        <div className='items marquee' aria-hidden="true">
                        {ExpertiseList.map((expertise) => {
                        return(
                            
                            <ExpertiseUno key={expertise.key} title={expertise.title} img={expertise.img} urlorg={expertise.urlorg} />
                        )
                    })}
                    </div>
                        </div>
                    {/*</div>*/}
                    <div className="container title expertises-title">
                            
                                <h3>Onboarding</h3>
                            
                        </div>
                    <ProcessFullScreen/>


                </div> </div>
            </div>
     );
}
 
export default Expertises;