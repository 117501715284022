import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useRef, useState} from "react";
import './css/bootstrap.css';
import './css/bootstrap-responsive.css';
import './css/icon-ie7.css';
import './css/base.css';
import './css/animate_all.css';
import './css/animate.css';

import './css/style.css';

import MainNavBar from './components/NavBar/MainNavBar';
import HomeSection from './pages/Home/Home';
import Servises from './pages/Services/Services';
import Team from './pages/Team/Team';
import Expertises from './pages/Team/Expertises';
import CaseStudies from './pages/Case/CaseStudies';
import Prices from './pages/Price/Pricing';
import ContactUs from './pages/Contact/ContactUs';
import Footer from './components/Footer/Footer';

function App() {
  const[theme, setTheme]=useState('dark')
  const HomeSectionRef = useRef();
  const ServisesSectionRef = useRef();
  const TeamSectionRef = useRef();
  const CaseStudiesSectionRef = useRef();
  const PricesSectionRef = useRef();
  const ContactUsSectionRef = useRef();

  const navHeader = [
    {
      headerTitle: "Home",
      headerRef: HomeSectionRef,
      headerID: "HomeSection"
    },
    {
      headerTitle: "Services",
      headerRef: ServisesSectionRef,
      headerID: "ServisesSection"
    },
    {
      headerTitle: "Team",
      headerRef: TeamSectionRef,
      headerID: "TeamSection"
    },
    {
      headerTitle: "Case Studies",
      headerRef: CaseStudiesSectionRef,
      headerID: "CaseStudiesSection"
    },
    {
        headerTitle: "Pricing",
        headerRef: PricesSectionRef,
        headerID: "PricesSection"
    },
    {
        headerTitle: "Contact Us",
        headerRef: ContactUsSectionRef,
        headerID: "ContactUsSection"
    }
  ]

  return (
    <div className="App">
      <MainNavBar  navHeader={navHeader} />
      <span ref={HomeSectionRef} id="HomeSection"></span>
      <HomeSection />
      <span ref={ServisesSectionRef} id="ServisesSection"></span>
      <Servises />
      <span ref={TeamSectionRef} id="TeamSection"></span>
      <Team />
      <Expertises/>
      <span ref={CaseStudiesSectionRef} id="CaseStudiesSection"></span>
      <CaseStudies />
      <span ref={PricesSectionRef} id="PricesSection"></span>
      <Prices />
      <span ref={ContactUsSectionRef} id="ContactUsSection"></span>
      <ContactUs />
      <Router>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
