import Teamimg1 from './../images/team/Anton-team.png';
import Teamimg2 from './../images/team/Artur-team.png';
import Teamimg3 from './../images/team/Team_V3.png';
import Teamimg4 from './../images/team/Team_I.png';
import Teamimg5 from './../images/team/Team5.jpeg'


const TeamList=[
    {
        id:'first-person',
        title:"Anton Makarevich",
        skills:"Certified PostgeSQL DBA, Cloud Architect, Cyber Security Expert",
        img: Teamimg1,
        facebook: "",
        twitter: "",
        linkedin: "",
    },
    {
        id:'second-person',
        title:"Artur Enaliev",
        skills:"Cloud Architect, Networking & Cyber Security Expert",
        img: Teamimg2,
        facebook: "",
        twitter: "",
        linkedin: "",
        
    },
    {
        id:'third-person',
        title:"Vitaly Savenkov",
        skills:"Certified Oracle DBA (OCP), Database Observability Expert",
        img: Teamimg3,
        facebook: "", 
        twitter: "",
        linkedin: "",  
},
    {
        id: 'forth-person',
        title: "Patrick Mitchel",
        skills: "Expert in Linux, DevOps and NoSQL Databases",
        img: Teamimg4,
        facebook: "",
        twitter: "",
        linkedin: "",
    },
{
    id:'fifth-person',
    title:"Andrey Tyurin",
    skills:"Microsoft Certified MSSQL DBA and Azure Solutions Architect Expert",
    img: Teamimg5,
    facebook: "", 
    twitter: "",
    linkedin: "",  
}
]
export {TeamList};