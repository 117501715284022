import { ServiceList } from './../../helpers/ServiceList';
import ServiceUno from './../../components/Service/Service';



const chunk = (arr, size) =>
Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
arr.slice(i * size, i * size + size)
);


const Servises = () => {
    const ArrRaw=chunk(ServiceList, 3);
    return ( 
        <div className="section primary-section" id="ServisesSection"> {/* Service section start */}
            <div className="triangle animated fadeInDown"></div>
            <div className="container">
                {/* Start title section */}
                <div className="title">
                    <h1>Services</h1>
                    {/* Section's title goes here */}
                    <p>What We Do?</p>
                    {/*Simple description for section goes here. */}
                </div>
                {
                ArrRaw.map((serviceRaw, index) => {
                        return(
                <div className="row-fluid" key={index*10}>
                    {serviceRaw.map((service) => {
                        return(
                            
                            <ServiceUno key={service.id} title={service.title} img={service.img} skills={service.skills} first={service.first}/>
                        )
                    })}
                </div>
                        )})
                }

            </div>{/* Service section end */}
        </div>
        
     );
}
 
export default Servises;